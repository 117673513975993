import { FORMAT, dayjs, isSameDay } from 'utils/date';

const isMidday = (dateTime: dayjs.Dayjs) => dateTime.hour() === 12 && dateTime.minute() === 0;

export const formatAmendCutOffDate = (date: string) =>
  dayjs(date).tz().format(FORMAT.LONG_DAY_MONTH);

export const formatAmendCutOffTime = (date: string) => {
  const cutOffDateTime = dayjs(date).tz();
  const cutOffTime = cutOffDateTime.format(`${FORMAT.HOUR_MINUTE_AMPM}`);
  return isMidday(cutOffDateTime) ? '12:00 midday' : cutOffTime.replace(':00', '');
};

export const formatAmendCutOffDateTime = (date: string) => {
  const isToday = isSameDay(dayjs(date).tz(), dayjs().tz());

  if (isToday) {
    return `${formatAmendCutOffTime(date)} today`;
  }

  return `${formatAmendCutOffTime(date)} on ${formatAmendCutOffDate(date)}`;
};
