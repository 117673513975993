import { SlotType } from '../services/slotOrchestration/types';

export enum FulfilmentCategories {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
}

export enum ServiceTypes {
  GROCERY = 'GROCERY',
  ENTERTAINING_PLACED_AT_HOME = 'ENTERTAINING_PLACED_AT_HOME',
}

const collectionCommon = {
  fulfilmentType: FulfilmentCategories.COLLECTION, // Branch service
  serviceType: ServiceTypes.GROCERY, // Branch service
  slotType: SlotType.GROCERY_COLLECTION, // Slot days and slots (get and put)
  category: FulfilmentCategories.COLLECTION,
  confirmationPanel: {
    headerText: 'Collection',
  },
  default: {
    message: 'Collection from',
  },
  fulfilmentAddress: {
    message: 'Free collection from:',
  },
  newAddress: {
    header: 'Find Branch',
    message: 'Enter town or postcode',
  },
  serviceSelection: {
    header: 'Click & Collect',
    message:
      // eslint-disable-next-line no-template-curly-in-string
      'Free collection from our stores. Minimum order ${collectionMinimumSpend} for groceries. No minimum spend for entertaining orders.',
  },
};

export const fulfilmentTypes = {
  DELIVERY: {
    gtmEventCategory: 'Delivery',
    id: 'delivery',
    fulfilmentType: FulfilmentCategories.DELIVERY, // Branch service
    serviceType: ServiceTypes.GROCERY, // Branch service
    slotType: SlotType.DELIVERY, // Slot days and slots (get and put)
    category: FulfilmentCategories.DELIVERY,
    path: 'delivery',
    name: 'Grocery Delivery',
    default: {
      message: 'Delivery to',
    },
    confirmationPanel: {
      headerText: 'Delivery',
    },
    fulfilmentAddress: {
      message: 'Free delivery to:',
      postcodeOnlyMessage: 'Yes, we deliver to:',
    },
    newAddress: {
      header: 'New Address',
      message: 'Enter postcode',
    },
    serviceSelection: {
      header: 'Delivery',
      // eslint-disable-next-line no-template-curly-in-string
      message: 'Choose a slot with free delivery. ${deliveryMinimumSpend} minimum spend applies',
    },
  },
  GROCERIES_COLLECTION: {
    // This should be GROCERY_COLLECTION, TD: WPIP-29223
    gtmEventCategory: 'Groceries Only Collection',
    id: 'groceries',
    ...collectionCommon,
    path: 'collection/groceries',
    name: 'Groceries',
    description: 'All your everyday groceries - from potatoes to pizza',
    seasonalDescription: 'Weekly Groceries, turkeys & Entertaining',
  },
  ENTERTAINING_COLLECTION: {
    gtmEventCategory: 'Entertaining Only Collection',
    id: 'entertaining',
    ...collectionCommon,
    serviceType: ServiceTypes.ENTERTAINING_PLACED_AT_HOME, // Branch service
    slotType: SlotType.ENTERTAINING_COLLECTION, // Slot days and slots (get and put)
    path: 'collection/entertaining',
    name: 'Entertaining',
    description: 'Delicious, ready-prepped options for stress-free celebrations',
    seasonalDescription:
      'Delicious, ready-prepped options and fabulous free-range turkeys for stress-free celebrations',
  },
  GROCERIES_AND_ENTERTAINING_COLLECTION: {
    gtmEventCategory: 'Groceries & Entertaining Collection',
    id: 'groceriesandentertaining',
    ...collectionCommon,
    path: 'collection/groceriesandentertaining',
    name: 'Groceries & Entertaining',
    description: 'Collect both your grocery and entertaining items from more than 150 stores.',
  },
};
