import React, { MouseEvent, PropsWithChildren } from 'react';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';

import { formatMenuClickEvent } from 'utils/tracking';

export type MenuExperienceFragmentClickHandlerProps = {
  trackingPrefix: string;
  type: 'mobile' | 'desktop';
  onClose: (event: MouseEvent) => void;
  additionalTrackingData?: Record<string, string>;
};

const MenuExperienceFragmentClickHandler = ({
  trackingPrefix,
  type,
  children,
  onClose,
  additionalTrackingData = {},
}: PropsWithChildren<MenuExperienceFragmentClickHandlerProps>) => {
  const { trackEvent } = useAnalytics();

  const handleClick = (event: MouseEvent) => {
    const { target } = event;
    let element: HTMLAnchorElement | null = null;

    // Putting ignore here as it's not actually possible to get an else here
    // It is not possible to "click" a non HTMLElement!!
    /* istanbul ignore else */
    if (target instanceof HTMLAnchorElement) {
      element = target;
    } else if (target instanceof HTMLElement) {
      element = target.closest('a');
    }

    if (element) {
      const { href, textContent } = element;

      if (href) {
        const trackingLabel = textContent || href;

        trackEvent(
          formatMenuClickEvent(`${trackingPrefix} | ${trackingLabel}`, {
            type,
            contentOrigin: 'aem',
            ...additionalTrackingData,
          }),
        );
        onClose(event);
      }
    }
  };

  return (
    // This just listens to bubbled events and is not actually interactive
    <div role="none" onClick={handleClick}>
      {children}
    </div>
  );
};

export default MenuExperienceFragmentClickHandler;
