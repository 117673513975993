import React, { memo, useEffect, useState } from 'react';
import styles from './SkipLink.module.scss';

// TODO - Revisit Skip Link ID - https://www.jlpit.com/jira/browse/WPIP-54390
export const SkipLink = memo(() => {
  const [href, setHref] = useState();

  useEffect(() => {
    // Using window location to jump to content as just using the hash redirects the page when used with NextJS
    const sanitizedURL = new URL(window.location.href);
    setHref(`${sanitizedURL.href.split('#')[0]}#main`);
  }, []);

  return (
    // deepcode ignore DOMXSS - href is constructed from window.location.href and not from user input.
    <a className={styles.skipLink} data-testid="skipLink" href={href}>
      Skip to main content
    </a>
  );
});

SkipLink.displayName = 'SkipLink';
