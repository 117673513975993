import { createSelector } from '@reduxjs/toolkit';

const getPage = state => state.page;

export const getMegaMenu = createSelector(getPage, ({ megaMenu = {} } = {}) => megaMenu);

export const getMegaMenuActiveLevel = createSelector(getMegaMenu, ({ activeLevel }) => activeLevel);

export const megaMenuIsOpen = createSelector(getMegaMenu, ({ isOpen = false }) => isOpen);

export const getMegaMenuMaxLength = createSelector(
  getMegaMenu,
  ({ maxLength = null }) => maxLength,
);

export const getMegaMenuRoot = createSelector(getMegaMenu, ({ menuRoot = null }) => menuRoot);

export const getMegaMenuMenus = createSelector(getMegaMenu, ({ menus = [] }) => menus);

export const getExperienceFragmentKeyForActiveMenuItem = createSelector(
  getMegaMenu,
  ({ menus }) => (menus.length > 0 && menus[menus.length - 1]?.banner) || undefined,
);
