/* eslint-disable no-underscore-dangle */
// THIS FILE SHOULD NOT BE IMPORTED. WEBPACK WILL TAKE CARE OF LOADING IT IF NEEDED.
// This file is only loaded in the browser, because Webpack has an alias set up to load this file instead of'./index.ts'. The server will use the original file.

const getPreloadedData = (fragmentName: string) => {
  const preloadedData = window.__WTR_COMMON__[fragmentName]?.preloadedData;

  if (preloadedData) {
    delete window.__WTR_COMMON__[fragmentName].preloadedData;
  }

  return preloadedData;
};

export default getPreloadedData;
