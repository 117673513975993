import { HttpResponse, ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import { logApiError } from 'logger';
import getApiBaseUrl from 'utils/getApiBaseUrl';
import { Branch } from './types';

type GetBranchError = {
  message: string;
};

export type GetBranchResult = {
  branch: Branch | null;
  error?: GetBranchError;
};

class BranchServiceClient extends ServiceClient {
  async getBranchById({
    authHeader,
    branchId,
  }: {
    authHeader: string;
    branchId: string;
  }): Promise<GetBranchResult> {
    const response = await this.httpClient
      .get<Branch>(`/branches/${branchId}`, {
        headers: {
          Authorization: authHeader,
          breadcrumb: config.applicationName,
        },
      })
      .catch(e => ({ error: e } as HttpResponse<Branch>));

    const branch = response.data;

    if (!branch || response.error) {
      logApiError({
        payload: {
          service: 'branch',
          url: `${getApiBaseUrl(config.services.branch.path)}/branches/${branchId}`,
          message: `Error getting branch: ${
            response.error?.message || 'Branch missing from response'
          }`,
          duration: response.duration,
          httpStatus: `${response.status}`,
          httpStatusCode: response.status,
        },
      });
    }

    return {
      branch: branch || null,
      error: response.error ? { message: response.error.message } : undefined,
    };
  }
}

const branchServiceClient = new BranchServiceClient(getApiBaseUrl(config.services.branch.path));

export default branchServiceClient;
