import { Provider as ReduxProvider } from 'react-redux';
import { loadableReady } from '@loadable/component';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AnalyticsProvider } from '@johnlewispartnership/wtr-website-analytics';
import { getAuthenticationProvider } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { AuthorizationServiceClient } from '@johnlewispartnership/wtr-website-authentication/dist/service';
import { createStore } from 'redux/store';
import { getAnalytics } from 'analytics';
import getPreloadedData from 'utils/dataPreloader/getPreloadedData';
import SiteHeader from 'components/SiteHeader';
import { PreloadedDataProvider } from 'contexts/PreloadedData';
import tokenServiceClient from 'services/token';
import config from 'config';
import { ShoppingContextProvider } from 'contexts/ShoppingContext';
import { TrolleyProvider } from 'contexts/Trolley';
import ReturnToAdmin from 'components/ReturnToAdmin';
import { CookieConsentProvider } from 'contexts/CookieConsent';
import { getCookiePreferences } from 'utils/cookie';
import { SlotProvider } from 'contexts/Slot';
import { MenuExperienceFragmentsProvider } from 'contexts/MenuExperienceFragments';
import { getApiResponseHandler } from 'client/utils/logging';
import { cookies } from 'client/utils/cookies';
import { headerPath } from 'server/controller/react/headerController/constants';
import { CommonModalProvider } from 'components/CommonModal/context';
import CommonModal from 'components/CommonModal';

const store = createStore();

const preloadedData = getPreloadedData('header');

const authorizationServiceClient = new AuthorizationServiceClient(
  config.services.authorizationServer.url,
);

const AuthenticationProvider = getAuthenticationProvider({
  tokenServiceClient,
  tokenServiceBasePath: config.services.tokenClient.path,
  authorizationServiceClient,
});

const handleAuthApiResponses = getApiResponseHandler(headerPath);

const renderApp = () => {
  const rootElement = createRoot(document.getElementById('common-header-root')!);
  // TODO hydrate store for single page app navigation here
  rootElement.render(
    <CookieConsentProvider cookieConsent={getCookiePreferences()}>
      <AuthenticationProvider
        initialState={{
          hasTokenSession: cookies.get('X-Has-Token-Session', { doNotParse: true }) === 'true',
        }}
        onApiResponse={handleAuthApiResponses}
      >
        <BrowserRouter>
          <ShoppingContextProvider>
            <SlotProvider>
              {/* TODO: TrolleyProvider should/could pick up server-side rendered initial state here */}
              <TrolleyProvider>
                <ReduxProvider store={store}>
                  <AnalyticsProvider
                    // eslint-disable-next-line no-underscore-dangle
                    initialEvents={window.__WTR_COMMON__?.header?.analytics}
                    analytics={getAnalytics()}
                  >
                    <PreloadedDataProvider preloadedData={preloadedData}>
                      <CommonModalProvider>
                        <MenuExperienceFragmentsProvider>
                          <ReturnToAdmin />
                          <SiteHeader />
                        </MenuExperienceFragmentsProvider>
                        <CommonModal />
                      </CommonModalProvider>
                    </PreloadedDataProvider>
                  </AnalyticsProvider>
                </ReduxProvider>
              </TrolleyProvider>
            </SlotProvider>
          </ShoppingContextProvider>
        </BrowserRouter>
      </AuthenticationProvider>
    </CookieConsentProvider>,
  );
};

loadableReady(
  () => {
    renderApp();
  },
  {
    namespace: 'common-header',
  },
);
