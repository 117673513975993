import { MEGA_MENU_PATH } from 'redux/modules/page/constants';

import { SET_MEGA_MENU } from 'redux/modules/page/actions/types';

import { categoryNameToUrl } from 'utils/format';

import { setMegaMenuActiveLevel } from 'redux/modules/page/actions/set-mega-menu-active-level';
import { getMegaMenuMenus } from 'redux/modules/page/selectors/mega-menu';
import { getCategoryNameById, getCategoryBannerById } from 'redux/modules/taxonomy/selectors';

export const setMegaMenu =
  (level, id, name = null) =>
  (dispatch, getState) => {
    const state = getState();
    const menus = getMegaMenuMenus(state);
    const pathPrefix = level > 0 ? menus[level - 1].path : MEGA_MENU_PATH;
    const categoryName = name ?? getCategoryNameById(state, id);
    const urlName = categoryNameToUrl(categoryName);
    const banner = getCategoryBannerById(state, id);

    dispatch({
      type: SET_MEGA_MENU,
      id,
      level,
      name: categoryName,
      path: `${pathPrefix}/${urlName}`,
      urlName,
      banner,
    });

    dispatch(setMegaMenuActiveLevel(level));
  };
