import React, { Fragment } from 'react';
import { array, bool, func, node, number, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { ChevronRight, Warning } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { logger } from 'logger';
import { SIGN_OUT } from 'constants/categoryIds';
import AnchorLink from 'components/AnchorLink';
import SignOut from 'components/AuthenticationAction/SignOut';
import { LazyIcon } from 'components/LazyIcon/LazyIcon';
import { useMenuExperienceFragments } from 'contexts/MenuExperienceFragments';
import styles from './MenuLink.module.scss';
import { useMenuItemNavigation } from '../hooks/use-menu-item-navigation';

const MenuLink = ({
  bold,
  hasDescendants,
  display,
  icon,
  id,
  children,
  closeMegaMenu,
  level,
  menus,
  name,
  offer,
  onClick,
  path,
  banner,
  showWarningIcon,
}) => {
  const isSignOut = id === SIGN_OUT;
  const isLink = path && !hasDescendants;
  const { handleClickItem, handleKeyDownItem, ref, tabIndex } = useMenuItemNavigation({
    display,
    hasDescendants,
    id,
    isLink,
    level,
    menus,
    name,
    onClick,
    closeMegaMenu,
    skip: !display || isSignOut,
  });

  const { fetchAndStoreFragment } = useMenuExperienceFragments();
  const { logout, state: authState } = useAuthentication();

  if (!display) return null;

  const testName = `${name ? `${name}-` : ''}menu-link-${id}`;
  const classes = classNames({
    [styles.bold]: bold,
    [styles.offer]: offer,
    [styles.showWarningIcon]: showWarningIcon,
  });
  const contents = (
    <>
      <div className={styles.nameWrapper}>
        {icon && <LazyIcon name={icon} className={styles.icon} size="small" />}
        {
          // eslint-disable-next-line react/jsx-no-useless-fragment
          children && <>{children}</>
        }
        {!children && name && <span className={styles.name}>{name}</span>}
        {showWarningIcon && <Warning className={styles.warningIcon} data-testid="warning-icon" />}
      </div>
      {hasDescendants && <ChevronRight className={styles.descendantsIcon} size="small" />}
    </>
  );

  if (isSignOut) {
    const logoutUser = async () => {
      try {
        await logout('/');
      } catch (error) {
        logger.error({
          payload: {
            message: `Logout error`,
            errorDetails: {
              error: error.message,
            },
          },
        });
      }
    };

    return (
      <SignOut
        loggedIn={authState.isLoggedIn}
        className={classNames(styles.button, classes)}
        data-testid={testName}
        ref={ref}
        onLogoutUser={logoutUser}
      />
    );
  }

  if (isLink) {
    return (
      <AnchorLink
        className={classNames(styles.link, classes)}
        data-actiontype="redirect"
        data-origincomponent="mobile megamenu link"
        data-shortdescription={name || children}
        ref={ref}
        onClick={handleClickItem}
        onKeyDown={handleKeyDownItem}
        tabIndex={tabIndex}
        to={path}
        data-testid={testName}
      >
        {contents}
      </AnchorLink>
    );
  }
  return (
    <button
      className={classNames(styles.button, classes)}
      onClick={event => {
        handleClickItem(event);
        if (hasDescendants && banner) {
          fetchAndStoreFragment(banner);
        }
      }}
      onKeyDown={handleKeyDownItem}
      ref={ref}
      tabIndex={tabIndex}
      type="button"
      data-testid={testName}
    >
      {contents}
    </button>
  );
};

MenuLink.displayName = 'MenuLink';

MenuLink.propTypes = {
  bold: bool,
  display: bool,
  hasDescendants: bool,
  icon: string,
  id: string,
  children: node,
  closeMegaMenu: func.isRequired,
  level: number,
  menus: array,
  name: string,
  offer: bool,
  onClick: func,
  path: oneOfType([string, shape({ hash: string, pathname: string, search: string })]),
  banner: string,
  showWarningIcon: bool,
};

MenuLink.defaultProps = {
  bold: false,
  display: false,
  hasDescendants: false,
  icon: undefined,
  id: undefined,
  children: null,
  level: undefined,
  menus: [],
  name: undefined,
  offer: false,
  onClick: null,
  path: undefined,
  banner: undefined,
  showWarningIcon: false,
};

export { MenuLink };
