import { ResponseDetails } from '@johnlewispartnership/wtr-website-authentication/dist/service/types';
import { logApiInfo, logApiError, LoggerRequestContext, logApiDebug } from 'logger';
import { sanitiseUrl } from 'utils/url';
import { isWindowAvailable } from 'utils/browser';

export const logServiceResponseDetails = (
  responseDetails: ResponseDetails[],
  requestContext?: LoggerRequestContext,
) =>
  responseDetails.forEach(details => {
    const dataForLogging = {
      payload: {
        service: details.service,
        url: details.url,
        path: details.path,
        duration: details.duration,
        status: details.status,
        message: details.error?.message,
        errorDetails: details.error,
        fragmentUrl: requestContext?.fragmentUrl || 'unknown',
        pageUrl:
          requestContext?.pageUrl ||
          (isWindowAvailable ? sanitiseUrl(window.location.href).toString() : 'unknown'),
      },
    };
    if (!details.isCacheHit) {
      if (
        !details.error ||
        (details.service === 'token' && details.path === '/v1/token' && details.status === 401)
      ) {
        logApiInfo(dataForLogging, requestContext);
      } else {
        logApiError(dataForLogging, requestContext);
      }
    } else {
      logApiDebug(dataForLogging, requestContext);
    }
  });
