import { ResponseDetails } from '@johnlewispartnership/wtr-website-authentication/dist/service/types';
import config from 'config';
import { logServiceResponseDetails } from 'utils/logging';
import { safeUrlConcat, sanitiseUrl } from 'utils/url';
import { cookies } from './cookies';

export const getApiResponseHandler =
  (fragmentPath: string) => (responseDetails: ResponseDetails[]) =>
    logServiceResponseDetails(responseDetails, {
      clientUserAgent: window.navigator.userAgent,
      customerId: cookies.get('customerId', { doNotParse: true }),
      fragmentUrl: safeUrlConcat(config.clientHost, fragmentPath),
      pageUrl: sanitiseUrl(window.location.href).toString(),
    });
