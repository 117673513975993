export const urls = {
  root: '/ecom',
  bookDeliverySlot: '/ecom/bookslot/delivery',
  bookEntertainingCollectionSlot: '/ecom/bookslot/collection/entertaining',
  bookGroceriesAndEntertainingCollectionSlot: '/ecom/bookslot/collection/groceriesandentertaining',
  valentines: '/ecom/shop/browse/groceries/valentines',
  groceriesChristmas: '/ecom/shop/browse/groceries/christmas',
  offers: '/ecom/shop/browse/offers',
  entertainingHome: '/ecom/shop/browse/entertaining',
  browse: '/ecom/shop/browse',
  new: '/ecom/shop/browse/groceries/new',
  groceriesHome: '/ecom/shop/browse/groceries',
  serviceSelection: '/ecom/serviceselection',
  favourites: '/ecom/favourites',
  myOrdersPage: '/ecom/myaccount/my-orders',
  myDetailsPage: '/ecom/my-details',
  lists: '/ecom/lists',
  myWaitrosePage: '/ecom/my-waitrose',
  login: '/ecom/login',
  homepage: '/',
  registration: '/ecom/registration',
  customerServiceHomePage: '/ecom/help-information/customer-service',
  inspiration: '/home/inspiration.html',
  orderConfirmation: 'order-confirmation',
  trolleyPage: '/ecom/shop/trolley',
  viewOrder: '/ecom/myaccount/my-orders/order/:customerOrderId',
  checkout: 'checkout',
  resolveOrderPayment: '/ecom/resolve-order-payment/:customerOrderId',
};

export const prodHost = 'www.waitrose.com';

export const specialistShopLinks = [
  {
    name: 'Cellar',
    tracking: 'topnav_control_cellar',
    url: 'http://www.waitrosecellar.com/',
  },
  {
    name: 'Garden',
    tracking: 'topnav_control_garden',
    url: 'http://www.waitrosegarden.com/',
  },
];
