import React from 'react';
import Alert from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Alert';
import Typography from '@johnlewispartnership/wtr-ingredients/dist/foundations/typography';
import { SlotType } from 'services/slotOrchestration/types';
import { isNumber } from 'utils/is-number';
import { useTrolley } from 'contexts/Trolley';
import { useSlotContext } from 'contexts/Slot';

export interface MinimumSpendNotMetMessageProps {
  isAlert?: boolean;
}

const MinimumSpendNotMetMessage = ({ isAlert = false }: MinimumSpendNotMetMessageProps) => {
  const { state: trolleyState } = useTrolley();
  const {
    slotContext: { details: slotDetails },
  } = useSlotContext();

  const { slotType = SlotType.UNDEFINED } = slotDetails || {};

  const collectionMinimumOrderValue =
    trolleyState?.trolley?.trolleyTotals?.collectionMinimumOrderValue?.amount;
  const deliveryMinimumOrderValue =
    trolleyState?.trolley?.trolleyTotals?.deliveryMinimumOrderValue?.amount;

  if (slotType === SlotType.ENTERTAINING_COLLECTION) return null;

  const isMinimumSpendKnown =
    isNumber(collectionMinimumOrderValue) && isNumber(deliveryMinimumOrderValue);

  const noSlotBookedMinimumOrderValue =
    isMinimumSpendKnown &&
    collectionMinimumOrderValue &&
    deliveryMinimumOrderValue &&
    Math.min(collectionMinimumOrderValue, deliveryMinimumOrderValue);

  let minimumSpendMessage = 'To check out, please meet our minimum spend';

  if (slotType === SlotType.DELIVERY && deliveryMinimumOrderValue) {
    minimumSpendMessage = `To check out, please meet our £${deliveryMinimumOrderValue} minimum spend (excluding delivery charge)`;
  } else if (slotType === SlotType.GROCERY_COLLECTION && collectionMinimumOrderValue) {
    minimumSpendMessage = `To check out, please meet our £${collectionMinimumOrderValue} minimum spend`;
  } else if (slotType === SlotType.UNDEFINED && noSlotBookedMinimumOrderValue) {
    minimumSpendMessage = `To check out, please meet our £${noSlotBookedMinimumOrderValue} minimum spend (excluding delivery charge if applicable)`;
  }

  return isAlert ? (
    <Alert
      type="warning"
      data-testid="minimum-spend-not-met-message-alert"
      message={minimumSpendMessage}
    />
  ) : (
    <Typography
      styleAs="paragraphHeading"
      data-testid="minimum-spend-not-met-message-text"
      noMargins
    >
      {minimumSpendMessage}
    </Typography>
  );
};

export default MinimumSpendNotMetMessage;
