import { createSelector } from '@reduxjs/toolkit';

import rootCategories, { ENTERTAINING, GROCERIES } from 'constants/categoryIds';
import { urls } from 'constants/urls';

import {
  getAccountMenu,
  getCategoryById,
  getCategoryUrlSegmentById,
  getSiteLinks,
} from 'redux/modules/taxonomy/selectors';

import decorateSubcategoryMenu from './decorate-subcategory-menu';

const getMegaMenu = ({ megaMenu } = {}) => megaMenu;

export const getMegaMenuActiveItemId = createSelector(
  getMegaMenu,
  ({ activeItem } = {}) => activeItem,
);

export const getMegaMenuHistory = createSelector(getMegaMenu, ({ history } = {}) => history || []);

export const getMegaMenuRoot = createSelector(getMegaMenu, ({ menuRoot } = {}) => menuRoot);

export const getMegaMenuOpen = createSelector(getMegaMenu, ({ open = false } = {}) => open);

const getUrlForActiveMegaMenuLevel = createSelector(
  [state => state, getMegaMenu],
  (state, { activeItem, menuRoot } = {}) => {
    if (menuRoot === activeItem) {
      return getCategoryUrlSegmentById(state, activeItem);
    }

    return [
      getCategoryUrlSegmentById(state, menuRoot),
      getCategoryUrlSegmentById(state, activeItem),
    ].join('/');
  },
);

const getPathForMegaMenu = createSelector(
  [state => state, getMegaMenuHistory],
  (state, history = []) =>
    history.length === 0
      ? getUrlForActiveMegaMenuLevel(state)
      : [
          getCategoryUrlSegmentById(state, history[0].menuRoot),
          ...history.map(({ activeItem } = {}) => getCategoryUrlSegmentById(state, activeItem)),
        ].join('/'),
);

const buildOffersPathFromHistory = createSelector(
  [state => state, getMegaMenuHistory],
  (state, history = []) =>
    [urls.offers]
      .concat(
        history
          .filter(({ activeItem }) => !Object.values(rootCategories).includes(activeItem))
          .map(({ activeItem }) => `${getCategoryUrlSegmentById(state, activeItem)}_offers`),
      )
      .join('/'),
);

const getOffersRoot = createSelector([getMegaMenuHistory, getMegaMenuRoot], (history, menuRoot) => {
  let root = menuRoot;

  if (history.length > 1) {
    const { menuRoot: firstRoot } = history[1];

    root = firstRoot;
  }
  return root;
});

const getOffersPath = createSelector(
  [state => state, getOffersRoot, getMegaMenuRoot],
  (state, offersRoot, menuRoot) => ({
    pathname: buildOffersPathFromHistory(state),
    search: menuRoot === GROCERIES ? null : `?categoryId=${menuRoot}`,
  }),
);

const getMegaMenuActiveMenu = createSelector(
  [
    state => state,
    getMegaMenuRoot, // store.megaMenu.menuRoot
    getMegaMenuHistory, // store.megaMenu.history
    getPathForMegaMenu, //
    getOffersPath, //
  ],
  (state, menuRoot, history, path, offersPath) => {
    const [{ activeItem: subCategoryRoot } = {}] = history;
    const hasOffersLink = history.length < 3;
    let activeMenu = getCategoryById(state, menuRoot);

    if (subCategoryRoot === GROCERIES) {
      activeMenu = decorateSubcategoryMenu(activeMenu, path, hasOffersLink && offersPath);
    }

    return activeMenu;
  },
);

export const getPreviousActiveTitle = createSelector(
  [state => state, getMegaMenuHistory],
  (state, history = []) => {
    const { menuRoot: previousActiveItem } = history[history.length - 1] || {};
    const { name } = getCategoryById(state, previousActiveItem) || {};

    return name;
  },
);

const getTopLevelNavigationMenus = createSelector(
  [getMegaMenuActiveMenu, getAccountMenu, getSiteLinks],
  (taxonomyMenu, accountMenu, siteLinks) =>
    [taxonomyMenu, accountMenu, siteLinks].filter(menuItem => menuItem),
);

const getNavigationMenusForLevel = createSelector([getMegaMenuActiveMenu], taxonomyMenu => {
  const menus = [taxonomyMenu];

  return menus.filter(menuItem => menuItem);
});

export const getNavigationMenus = createSelector(
  [(state, isLoggedIn) => ({ state, isLoggedIn }), getMegaMenuHistory],
  ({ state = {}, isLoggedIn }, history = []) => {
    const level = history.length;

    if (level === 0) {
      return getTopLevelNavigationMenus(state, isLoggedIn);
    }

    return getNavigationMenusForLevel(state, level);
  },
);

export const getActiveItem = createSelector(
  [state => state, getMegaMenuActiveItemId],
  (state, activeItem) => (activeItem ? getCategoryById(state, activeItem) : activeItem),
);

export const getPathForMegaMenuItemById = createSelector(
  [state => state, (_, id) => id, getMegaMenuHistory, getPathForMegaMenu],
  (state, id, history, path) => {
    if (id === ENTERTAINING) return urls.entertainingHome;
    return history.length === 0
      ? getUrlForActiveMegaMenuLevel(state)
      : [path, getCategoryUrlSegmentById(state, id)].join('/');
  },
);

export const getCurrentMenuList = createSelector(
  [state => state, getNavigationMenus],
  (state, navigationMenus = []) =>
    []
      .concat(...navigationMenus.map(({ categoryIds = [] }) => categoryIds))
      .map(category => (typeof category === 'object' ? category.id : category))
      .filter(categoryId => categoryId)
      .filter(categoryId => {
        const { emptyOfProducts = false } = getCategoryById(state, categoryId);

        return !emptyOfProducts;
      }),
);

export const getExperienceFragmentKeyForActiveMenuItem = createSelector(
  [getMegaMenuActiveMenu],
  activeItem => activeItem?.banner,
);
