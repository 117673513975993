import { SlotType } from '../services/slotOrchestration/types';
import { fulfilmentTypes } from '../constants/fulfilment-types';

export const getFulfilmentTypeForBookedSlot = (slotType: SlotType) => {
  let result;

  if (slotType === fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION.slotType) {
    result = fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION;
  }

  if (slotType === fulfilmentTypes.ENTERTAINING_COLLECTION.slotType) {
    result = fulfilmentTypes.ENTERTAINING_COLLECTION;
  }

  if (slotType === fulfilmentTypes.DELIVERY.slotType) {
    result = fulfilmentTypes.DELIVERY;
  }

  return result;
};
