import React, { useState } from 'react';
import { Close, Pen } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/dist/foundations/typography';
import { Tooltip } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Tooltip';
import classNames from 'classnames';
import { useSlotContext } from 'contexts/Slot';
import GoToCheckout from 'components/GoToCheckout';
import { formatSlotTime } from 'utils/format-slot-time';
import { dayjs, FORMAT } from 'utils/date';
import { getFulfilmentTypeForBookedSlot } from 'utils/get-fulfilment-type';
import { useTrolley } from 'contexts/Trolley';
import { getOrderType } from 'utils/get-order-type';
import { AmendCutOffMessage } from './AmendCutOffMessage';
import styles from './AmendOrderAlert.module.scss';

export interface AmendOrderAlertProps {
  isOpen?: boolean;
  onToggle?: () => void;
  onCancelChanges: () => void;
}

export const AmendOrderAlert = ({ isOpen, onCancelChanges, onToggle }: AmendOrderAlertProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);
  const {
    slotContext: { details: slotDetails },
  } = useSlotContext();
  const { state: trolleyState } = useTrolley();

  const {
    amendOrderCutoffDateTime,
    slotType,
    startDateTime: slotStartTime,
    endDateTime: slotEndTime,
  } = slotDetails || {};

  if (!slotDetails || !amendOrderCutoffDateTime || !slotType || !slotStartTime || !slotEndTime)
    return null;

  const orderType = getOrderType({
    slotType,
    containsEntertainingLines: trolleyState?.order?.containsEntertainingLines || false,
  });

  const {
    // @ts-ignore - confirmationPanel always exists on object but not detected due to an issue caused by spread operator
    confirmationPanel: { headerText: fulfilmentTypeMessage },
  } = getFulfilmentTypeForBookedSlot(slotType);

  const onExpandClick = () => {
    setIsTooltipOpen(false);
    onToggle?.();
  };

  return (
    <div className={styles.amendOrderAlert} data-testid="amendOrderAlert">
      <section className={classNames(styles.titleSection, { [styles.open]: isOpen })}>
        <div className={styles.iconContainer}>
          <Pen />
          <Typography className={styles.amendIconTxt} styleAs="paragraphHeading">
            AMENDING
          </Typography>
        </div>

        <button
          data-testid="expand-bar-btn"
          aria-label="Expand"
          className={styles.expandChevron}
          onClick={onExpandClick}
          type="button"
        />
      </section>
      <section className={styles.details}>
        <p className={styles.slotInfo}>
          <span className={styles.slotDate} data-testid="slotDate">
            {`${fulfilmentTypeMessage} on ${dayjs(slotStartTime).format(FORMAT.LONG_DAY_MONTH)}`}
          </span>
          <span className={styles.slotTime} data-testid="slotTime">
            {` ${formatSlotTime(slotStartTime)} - ${formatSlotTime(slotEndTime)}`}
          </span>
        </p>
        <p>
          <span className={styles.amendOrderCutOff} data-testid="amendOrderCutOff">
            <AmendCutOffMessage
              slotDate={slotStartTime}
              amendOrderCutOff={amendOrderCutoffDateTime}
              orderType={orderType}
            />
          </span>
        </p>
      </section>

      <section className={styles.ctas}>
        {/* // ProceedThroughCheckout component normally here but that uses unnecessary
        logic around interstitials for the PDP so simplified and the nested
        GoToCheckout component has been used instead */}
        <GoToCheckout
          messagePlacement="topPopover"
          buttonCheckoutId="checkout-amend-order-alert"
          // location="Amend snackbar"
          theme="primaryWhite"
          useLightTooltip
        />
        <button
          className={styles.discard}
          data-testid="cancel-amendment-changes"
          onClick={onCancelChanges}
          type="button"
        >
          Discard amends
        </button>
      </section>
      <Tooltip
        content={
          <div className={styles.checkoutTooltipContent}>
            <Typography styleAs="paragraphSmall" className={styles.checkoutTooltipText}>
              Don&apos;t forget to checkout after
              <br />
              amending your order
            </Typography>
            <div>
              <Close
                size="xsmall"
                onClick={() => setIsTooltipOpen(!isTooltipOpen)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        }
        placement="top-end"
        distanceOffset={25}
        skiddingOffset={0}
        disableFocusListener
        disableTouchListener
        disableHoverListener
        isOpen={isTooltipOpen && !isOpen}
      >
        <span aria-hidden className={styles.tooltipReference} />
      </Tooltip>
    </div>
  );
};

AmendOrderAlert.displayName = 'AmendOrderAlert';
