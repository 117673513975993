import { SlotType } from 'services/slotOrchestration/types';
import { OrderTypes } from 'types/Checkout/order-type';

export const getOrderType = ({
  slotType,
  containsEntertainingLines,
}: {
  slotType: SlotType;
  containsEntertainingLines: boolean;
}) => {
  if (!slotType || slotType === SlotType.UNDEFINED) {
    return null;
  }

  if (slotType === SlotType.ENTERTAINING_COLLECTION) {
    return OrderTypes.ENTERTAINING;
  }

  if (containsEntertainingLines) {
    return OrderTypes.GROCERIES_ENTERTAINING;
  }

  return OrderTypes.GROCERIES;
};
