import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  ModalCloseEvent,
  ModalProps,
} from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal/Modal';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import usePrevious from 'hooks/use-previous';
import { ModalSeverityValues } from 'constants/modals/modalSeverityValues';

export type ValueOf<T> = Required<T>[keyof T];

export type CommonModalProps = ModalProps & {
  id: string;
  severity?: ValueOf<typeof ModalSeverityValues>;
};

export type CommonModalContextValue = {
  state: CommonModalProps;
  openModal: (modalProps: CommonModalProps) => void;
  closeModal: (event?: ModalCloseEvent) => void;
};

export const CommonModalContext = createContext<CommonModalContextValue | undefined>(undefined);

export const CommonModalProvider: FC<{
  initialState?: CommonModalContextValue['state'];
  children: React.ReactNode;
}> = ({
  children,
  initialState = { isOpen: false, id: '', severity: ModalSeverityValues.INFO },
}) => {
  const { trackEvent } = useAnalytics();
  const [modalProps, setModalProps] = useState<CommonModalProps>(initialState);
  const {
    state: { customerId },
  } = useAuthentication();
  const previousIsOpen = usePrevious(modalProps.isOpen);
  const modalJustOpened = modalProps.isOpen && modalProps.isOpen !== previousIsOpen;

  useEffect(() => {
    if (modalJustOpened) {
      trackEvent({
        event: 'modal_opened',
        customerId: customerId || '-1',
        modal: {
          id: modalProps.id,
          title: modalProps.titleText,
          severity: modalProps.severity || ModalSeverityValues.INFO,
        },
      });
      if (modalProps.severity === ModalSeverityValues.ERROR) {
        const errorToSendToApm = new Error('Error modal opened');
        window?.apm?.captureError(
          Object.assign(errorToSendToApm, {
            event: 'modal_opened',
            modalId: modalProps.id,
            modalSeverity: modalProps.severity,
            modalTitle: modalProps.titleText,
          }),
        );
      }
    }
  }, [
    customerId,
    modalJustOpened,
    modalProps.id,
    modalProps.severity,
    modalProps.titleText,
    trackEvent,
  ]);

  const openModal = useCallback(
    (newModalProps: Omit<CommonModalProps, 'isOpen'>) =>
      setModalProps({ ...newModalProps, isOpen: true } as CommonModalProps),
    [],
  );

  const closeModal = useCallback(
    (event?: ModalCloseEvent) => {
      trackEvent({
        event: 'modal_closed',
        customerId: customerId || '-1',
        modal: {
          id: modalProps.id,
          title: modalProps.titleText,
          severity: modalProps.severity || ModalSeverityValues.INFO,
          ...(event?.label && { reason: event.label }),
        },
      });
      setModalProps({ ...modalProps, isOpen: false });
    },
    [customerId, modalProps, trackEvent],
  );

  const value: CommonModalContextValue = useMemo(
    () => ({
      state: modalProps,
      openModal,
      closeModal,
    }),
    [modalProps, openModal, closeModal],
  );
  return <CommonModalContext.Provider value={value}>{children}</CommonModalContext.Provider>;
};

export const useCommonModal = (): CommonModalContextValue => {
  const context = useContext(CommonModalContext);

  if (context === undefined) {
    throw new Error('useCommonModal must be used within a CommonModalProvider');
  }

  return context;
};
