import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { Container } from '@johnlewispartnership/wtr-ingredients/dist/foundations/grid';
import { Search } from '@johnlewispartnership/wtr-ingredients/dist/foundations/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { BREAKPOINTS } from 'constants/grid';
import TopNav from 'components/SiteHeader/TopNav';
import HeaderLogo from 'components/HeaderLogo';
import SiteHeaderOverlay from 'components/SiteHeader/SiteHeaderOverlay/SiteHeaderOverlay';
import setClearValueAction from 'redux/modules/page/actions/set-clear-value';
import setMobileOverlayAction from 'redux/modules/page/actions/set-mobile-overlay';
import { useScrolled } from 'hooks/use-scrolled';
import SearchForm from 'components/Search/SearchForm';
import SkipLink from 'components/SkipLink';
import { stickyHeaderOffset } from 'constants/siteHeader';
import { usePreloadedData } from 'contexts/PreloadedData';
import contentService from 'services/content';
import useMonetateReporter from 'hooks/use-monetate-reporter';
import { useSlotContext } from 'contexts/Slot';
import { deleteCookie, setCookie } from 'utils/cookie';
import SitePinBar from 'components/SitePinBar';
import SlideOutNav from './SlideOutNav';
import SlotButton from './SlotButton';
import styles from './SiteHeader.module.scss';
import DropdownNav from './LinksBar/DropdownNav';
import DropdownNavHidden from './LinksBar/DropdownNavHidden';
import MobileOverlay from './MobileOverlay';
import LinksBar from './LinksBar';
import ShoppingSummaryContainer from './ShoppingSummary/ShoppingSummaryContainer';

const fetchContent = async (experienceFragmentKey, hasTokenSession) =>
  contentService.getContent(experienceFragmentKey, hasTokenSession);

const SiteHeader = () => {
  const { state: authState } = useAuthentication();

  const isMegaMenuOpen = useSelector(state => state.page.megaMenu.isOpen);
  const isOverlayOpen = useSelector(state => state.page.isMobileOverlayOpen);
  const clearValue = useSelector(state => state.page.isValueClear);
  const dispatch = useDispatch();
  useMonetateReporter();

  const [isTablet, setIsTablet] = useState(false);

  const setClearValue = useCallback(
    isValueClear => dispatch(setClearValueAction(isValueClear)),
    [dispatch],
  );
  const setMobileOverlay = useCallback(
    isOpen => dispatch(setMobileOverlayAction(isOpen)),
    [dispatch],
  );
  const [isSeasonalMenuOpen, setIsSeasonalMenuOpen] = useState(false);
  const toggleOverlay = useCallback(() => {
    setClearValue(true);
    setMobileOverlay(!isOverlayOpen);
  }, [isOverlayOpen, setClearValue, setMobileOverlay]);

  const ref = useRef();
  const { scrolled } = useScrolled(stickyHeaderOffset);
  const placeholderStyle = useMemo(() => {
    const { current: el } = ref;
    if (!scrolled || !el) return { display: 'none' };
    return { height: el.getBoundingClientRect().height };
  }, [scrolled]);

  // DropdownNav is rendered at a different position depending on whether it
  // is sticky or not so that the tabbing order is correct alongside search form/header buttons
  const menu = (
    <div className={styles.menu} key="desktopMenu">
      <DropdownNav scrolled={scrolled} />
    </div>
  );

  const isTabletBreakpoint = useMediaQuery({ minWidth: BREAKPOINTS.md, maxWidth: BREAKPOINTS.lg });

  // Set customerId cookie for server-side reporting purposes
  useEffect(() => {
    if (authState.initialised) {
      if (authState.isLoggedIn) {
        setCookie('customerId', authState.customerId);
      } else {
        deleteCookie('customerId');
      }
    }
  }, [authState.customerId, authState.initialised, authState.isLoggedIn]);

  useEffect(() => {
    setIsTablet(isTabletBreakpoint);
  }, [isTabletBreakpoint]);

  const { preloadedData, setPreloadedData } = usePreloadedData();

  useEffect(() => {
    if (
      typeof preloadedData?.middleMegaMenu === 'undefined' ||
      typeof preloadedData?.seasonalMenu === 'undefined'
    ) {
      const middleMegaMenuContent =
        typeof preloadedData?.middleMegaMenu === 'undefined'
          ? fetchContent('menu/middle-mega-menu/master', authState.hasTokenSession)
          : preloadedData.middleMegaMenu;

      const seasonalMenuContent =
        typeof preloadedData?.seasonalMenu === 'undefined'
          ? fetchContent(
              'menu/seasonal-menu/seasonal-menu-content/master',
              authState.hasTokenSession,
            )
          : preloadedData.seasonalMenu;

      Promise.allSettled([middleMegaMenuContent, seasonalMenuContent]).then(
        ([middleMegaMenu, seasonalMenu]) => {
          setPreloadedData({
            middleMegaMenu: middleMegaMenu.value,
            seasonalMenu: seasonalMenu.value,
          });
        },
      );
    }
  }, [preloadedData, setPreloadedData, authState.hasTokenSession]);

  const shouldRenderLoggedInVersion =
    (authState.hasTokenSession && !authState.initialised) ||
    (authState.initialised && authState.isLoggedIn);

  const {
    slotContext: {
      loading: slotLoading,
      booked: slotBooked,
      details: slotDetails,
      error: slotError,
      address: slotAddress,
    },
  } = useSlotContext();

  return (
    <div className={styles.header} data-testid="site-header">
      <SkipLink />
      <TopNav />
      <div className={styles.desktop}>
        <DropdownNavHidden />
        {scrolled && <div data-testid="DesktopHeaderPlaceholder" style={placeholderStyle} />}
        <div
          className={classNames(styles.desktopHeader, {
            [styles.scrolled]: scrolled,
          })}
          data-testid="DesktopHeader"
          ref={ref}
        >
          <Container className={classNames(styles.container)}>
            <div className={styles.mobileMenu}>
              <SlideOutNav
                isSlotBooked={slotBooked}
                slotDetails={slotDetails}
                slotLoading={slotLoading}
                isLoggedIn={shouldRenderLoggedInVersion}
              />
            </div>
            <div className={styles.mobileLogo}>
              <HeaderLogo small staticRender={false} />
            </div>
            <div className={styles.logo}>
              <HeaderLogo staticRender={false} />
            </div>

            {scrolled && menu}

            <div className={styles.inner}>
              <div className={styles.mobileSearch}>
                <button
                  aria-label="Search"
                  className={styles.searchBtn}
                  type="button"
                  onClick={toggleOverlay}
                  data-testid="searchBtn"
                >
                  <Search />
                </button>
              </div>
              <div className={styles.search}>
                <SearchForm placeholder={isTablet ? 'Search...' : 'Search groceries...'} />
              </div>
              <div className={styles.mobileSlot}>
                <SlotButton
                  isSlotBooked={slotBooked}
                  slotDetails={slotDetails}
                  slotAddress={slotAddress}
                  slotLoading={slotLoading && shouldRenderLoggedInVersion}
                  slotError={slotError}
                  testIdSuffix="mobile"
                  isMobile
                />
              </div>
              <div className={styles.slot}>
                <SlotButton
                  isSlotBooked={slotBooked}
                  slotDetails={slotDetails}
                  slotAddress={slotAddress}
                  slotLoading={slotLoading && shouldRenderLoggedInVersion}
                  slotError={slotError}
                />
              </div>
              <ShoppingSummaryContainer />
            </div>

            {!scrolled && menu}

            {!scrolled && (
              <div className={styles.links}>
                <LinksBar
                  showContactAddressNotPresentNotification={false}
                  currentPageIsGroceriesHomePage={false}
                  isLoggedIn={shouldRenderLoggedInVersion}
                  onToggleSeasonalMenu={() => setIsSeasonalMenuOpen(!isSeasonalMenuOpen)}
                  isSeasonalMenuOpen={isSeasonalMenuOpen}
                />
              </div>
            )}
          </Container>
        </div>
      </div>
      <div className={styles.overlay}>
        <SiteHeaderOverlay isActive={isMegaMenuOpen || isSeasonalMenuOpen} />
      </div>
      {isOverlayOpen && <MobileOverlay clearValue={clearValue} setClearValue={setClearValue} />}
      <SitePinBar />
    </div>
  );
};

export { SiteHeader };
