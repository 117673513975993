import React, { FC, ReactNode, useMemo, useState } from 'react';
import {
  MenuExperienceFragmentsContext,
  type MenuExperienceFragments,
  type MenuExperienceFragmentsContextValue,
} from './MenuExperienceFragmentsContext';

const MenuExperienceFragmentsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [experienceFragments, setExperienceFragments] = useState<
    MenuExperienceFragments | undefined
  >(undefined);

  const providerValue: MenuExperienceFragmentsContextValue = useMemo(
    () => ({
      experienceFragments,
      setExperienceFragments,
    }),
    [experienceFragments, setExperienceFragments],
  );

  return (
    <MenuExperienceFragmentsContext.Provider value={providerValue}>
      {children}
    </MenuExperienceFragmentsContext.Provider>
  );
};

export default MenuExperienceFragmentsProvider;
