import React, { useRef } from 'react';
import {
  ContentComponentLibraryProvider,
  TradingCell,
} from '@johnlewispartnership/wtr-content-component-library';
import { TRADING_CELL } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/constants/cmsResourceTypes';
import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';

import config from 'config';
import { useMenuExperienceFragments } from 'contexts/MenuExperienceFragments';
import MenuExperienceFragmentClickHandler, {
  MenuExperienceFragmentClickHandlerProps,
} from 'cms-components/MenuExperienceFragmentClickHandler/MenuExperienceFragmentClickHandler';

const contentLibraryConfig = {
  cfDomain: config.cfDomain,
  componentMap: new Map(),
};

type MenuBannerExperienceFragmentProps = {
  experienceFragmentKey: string;
  onClose: () => void;
} & Pick<MenuExperienceFragmentClickHandlerProps, 'type'>;

const MenuBannerExperienceFragment = ({
  experienceFragmentKey,
  type,
  onClose,
}: MenuBannerExperienceFragmentProps) => {
  const tradingCellRef = useRef();
  const { trackEvent } = useAnalytics();
  const { experienceFragments } = useMenuExperienceFragments();
  const content = experienceFragments?.[experienceFragmentKey];

  if (!content?.locations?.banner || content?.locations?.banner[0].resourceType !== TRADING_CELL)
    return null;

  return (
    <ContentComponentLibraryProvider
      // @ts-expect-error onError will never be invoked as we are not using a ComponentMap.
      config={{ ...contentLibraryConfig, onAnalyticsEvent: trackEvent }}
    >
      <MenuExperienceFragmentClickHandler
        trackingPrefix="adtech-xf"
        type={type}
        additionalTrackingData={{ xf: experienceFragmentKey }}
        onClose={onClose}
      >
        <TradingCell {...content.locations.banner[0]} ref={tradingCellRef} />
      </MenuExperienceFragmentClickHandler>
    </ContentComponentLibraryProvider>
  );
};

export default MenuBannerExperienceFragment;
