import { print } from 'graphql/language/printer';
import { ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import { logApiError, logApiInfo } from 'logger';
import getApiBaseUrl from 'utils/getApiBaseUrl';
import { safeUrlConcat } from 'utils/url';
import {
  GetTrolleyResult,
  GraphQuery,
  GetTrolleyResponseData,
  GetCancelAmendOrderResult,
  GetCancelAmendOrderResponseData,
} from './types';
import getTrolley from './queries/getTrolley.graphql';
import cancelAmendOrder from './mutations/cancelAmendOrder.graphql';

const notFoundErrorCodes = [404, 400];

class GraphQLServiceClient extends ServiceClient {
  async getTrolley(orderId: string, authHeader: string): Promise<GetTrolleyResult> {
    const apiPath = 'graph/live?clientType=WEB_APP&tag=getTrolley';
    // TODO - Passing identical variables as 2 different params. Workaround for strict Graph types that require a breaking change in the Graph repo
    const response = await this.httpClient.post<GraphQuery, GetTrolleyResponseData>(
      apiPath,
      {
        query: `${print(getTrolley)}`,
        variables: {
          trolleyQueryOrderId: orderId,
          orderQueryOrderId: orderId,
        },
      },
      {
        headers: {
          Authorization: authHeader,
          breadcrumb: config.applicationName,
          contentType: 'application/json',
        },
      },
    );

    const { duration } = response;
    logApiInfo({
      payload: {
        duration,
        service: 'graphql',
        url: `${safeUrlConcat(this.baseUrl, apiPath)}`,
        message: `GraphQL API request took ${duration}ms`,
        httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
        httpStatusCode: response.status,
      },
    });

    const { trolley, failures, slotChangeable } = response.data?.data?.getTrolley ?? {};
    const { order } = response.data?.data.getOrderWithRecommendations ?? {};
    const { totalEstimatedCost, trolleyItemCounts } = trolley?.trolleyTotals ?? {};
    const { hardConflicts = 0, noConflicts = 0, softConflicts = 0 } = trolleyItemCounts ?? {};
    const totalNumberOfItems = hardConflicts + noConflicts + softConflicts;

    let error;
    if (response.error) {
      const isNotFoundError = notFoundErrorCodes.includes(response.status || -1);
      error = {
        message: response.error.message,
      };

      if (!isNotFoundError) {
        logApiError({
          payload: {
            service: 'graphql',
            url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
            message: 'Error loading trolley',
            errorDetails: error,
            duration,
            httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
            httpStatusCode: response.status,
          },
        });
      }
    } else if (failures?.length) {
      const { message, type } = failures[0];
      const isNotFoundError = notFoundErrorCodes.includes(Number(type));

      error = {
        message,
        type,
      };

      if (!isNotFoundError) {
        logApiError({
          payload: {
            service: 'graphql',
            duration,
            url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
            message: 'Error loading trolley (graphQL failure)',
            httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
            httpStatusCode: response.status,
            errorDetails: error,
          },
        });
      }
    }

    return {
      trolley,
      order,
      error,
      slotChangeable: !!slotChangeable,
      totalEstimatedCost: totalEstimatedCost?.amount || 0,
      totalNumberOfItems,
    };
  }

  async cancelAmendOrder(orderId: string, authHeader: string): Promise<GetCancelAmendOrderResult> {
    const apiPath = 'graph/live?clientType=WEB_APP&tag=cancel-amend-order';

    const response = await this.httpClient.post<GraphQuery, GetCancelAmendOrderResponseData>(
      apiPath,
      {
        query: `${print(cancelAmendOrder)}`,
        variables: {
          customerOrderId: orderId,
        },
      },
      {
        headers: {
          Authorization: authHeader,
          breadcrumb: config.applicationName,
          contentType: 'application/json',
        },
      },
    );

    const { duration } = response;
    logApiInfo({
      payload: {
        duration,
        service: 'graphql',
        url: `${safeUrlConcat(this.baseUrl, apiPath)}`,
        message: `GraphQL API request took ${duration}ms`,
        httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
        httpStatusCode: response.status,
      },
    });

    const { failures } = response.data?.data?.cancelAmendOrder ?? {};

    let error;
    if (response.error) {
      const isNotFoundError = notFoundErrorCodes.includes(response.status || -1);
      error = {
        message: response.error.message,
      };

      if (!isNotFoundError) {
        logApiError({
          payload: {
            service: 'graphql',
            url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
            message: 'Error cancelling amend order',
            errorDetails: error,
            duration,
            httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
            httpStatusCode: response.status,
          },
        });
      }
    } else if (failures?.length) {
      const { message, type } = failures[0];
      const isNotFoundError = notFoundErrorCodes.includes(Number(type));

      error = {
        message,
        type,
      };

      if (!isNotFoundError) {
        logApiError({
          payload: {
            service: 'graphql',
            duration,
            url: `${getApiBaseUrl(config.services.graphql.path)}/${apiPath}`,
            message: 'Error cancelling amend order (graphQL failure)',
            httpStatus: `${response.status}`, // TODO add statusText (see Logging Standards)
            httpStatusCode: response.status,
            errorDetails: error,
          },
        });
      }
    }

    return {
      error,
    };
  }
}

export default new GraphQLServiceClient(getApiBaseUrl(config.services.graphql.path));

export type { GraphQLServiceClient, GetTrolleyResponseData, GetCancelAmendOrderResponseData };
