import { connect } from 'react-redux';
import {
  getMegaMenuActiveLevel,
  getMegaMenuMaxLength,
  getMegaMenuMenus,
  megaMenuIsOpen,
  getExperienceFragmentKeyForActiveMenuItem,
} from 'redux/modules/page/selectors/mega-menu';
import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { setMegaMenuState } from 'redux/modules/page/actions/set-mega-menu-state';
import { getIsTaxonomyLoading } from 'redux/modules/taxonomy/selectors';
import MegaMenu from './Menus';

export default connect(
  state => ({
    activeLevel: getMegaMenuActiveLevel(state),
    isOpen: megaMenuIsOpen(state),
    loading: getIsTaxonomyLoading(state),
    maxMenus: getMegaMenuMaxLength(state),
    menus: getMegaMenuMenus(state),
    experienceFragmentKeyForActiveMenuItem: getExperienceFragmentKeyForActiveMenuItem(state),
  }),
  {
    onToggle: setMegaMenuState,
    setMegaMenu,
  },
)(MegaMenu);
