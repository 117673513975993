import React from 'react';
import { CmsComponentData } from '@johnlewispartnership/wtr-content-component-library';

export interface MenuExperienceFragments {
  [k: string]: {
    [k: string]: unknown;
    locations: {
      banner: CmsComponentData[];
    };
  };
}

export type MenuExperienceFragmentsContextValue = {
  experienceFragments: MenuExperienceFragments | undefined;
  setExperienceFragments: (
    args: (value: MenuExperienceFragments | undefined) => MenuExperienceFragments,
  ) => void;
};

const MenuExperienceFragmentsContext = React.createContext<
  MenuExperienceFragmentsContextValue | undefined
>(undefined);

export { MenuExperienceFragmentsContext };
