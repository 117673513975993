import { ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import getApiBaseUrl from 'utils/getApiBaseUrl';
import asyncCache from 'utils/async-cache';
import { logApiError, logApiInfo, logger } from 'logger';
import { GetSearchResponseData } from './types/GetSearchResponseData';

type GetSearchError = {
  message: string;
};

export type GetSearchResult = {
  results: any;
  error?: GetSearchError;
};

const searchApiUrl = getApiBaseUrl(config.services.search.path);

class SearchServiceClient extends ServiceClient {
  // TODO: Consider renaming this function
  async getResults(searchKey: string, hasTokenSession: boolean): Promise<GetSearchResult> {
    const response = await asyncCache(
      this.httpClient.get<GetSearchResponseData>,
      [
        searchKey,
        {
          headers: {
            Authorization: `Bearer unauthenticated`,
            breadcrumb: config.applicationName,
            ...(hasTokenSession && { 'x-kept-unauthenticated': 'true' }),
          },
        },
      ],
      {
        maxAge: 60000,
        onCacheHit: () =>
          logger.info({
            payload: { message: 'Search Results loaded from cache', service: 'search' },
          }),
        onCallThrough: ({ value }) => {
          if (!value?.error) {
            logApiInfo({
              payload: {
                duration: value?.duration,
                service: 'search',
                url: searchApiUrl,
                message: `Search Results loaded from server, request took ${value?.duration}ms`,
                httpStatus: String(value?.status),
                httpStatusCode: value?.status,
              },
            });
          } else {
            logApiError({
              payload: {
                duration: value?.duration,
                service: 'search',
                url: searchApiUrl,
                message: `Error loading search: ${value?.error.message}`,
                httpStatus: String(value?.status),
                httpStatusCode: value?.status,
              },
            });
          }
        },
      },
    );
    const searchResult = response.data;

    return {
      results: searchResult || null,
      error: response.error ? { message: response.error.message } : undefined,
    };
  }
}

export default new SearchServiceClient(searchApiUrl);
