import queryString from 'query-string';

const queryParamWhitelist: string[] = ['mfeEnable'];
export const sanitiseUrl = (url: string | URL) => {
  const { searchParams: query } = new URL(url);

  const placeholder = 'mwm';
  const cleanParams = Array.from(query.entries()).reduce(
    (lastResult, [key, value]) => ({
      ...lastResult,
      [key]: queryParamWhitelist.includes(key) ? value : placeholder,
    }),
    {} as ReturnType<typeof query.entries>,
  );
  const cleanQueryString = queryString.stringify(cleanParams);

  return new URL(
    `${url.toString().split('?')[0]}${cleanQueryString ? `?${cleanQueryString}` : ''}`,
  );
};

export const safeUrlConcat = (url: string, pathToAdd: string) => {
  const urlObj = new URL(url);
  const [pathname, search] = pathToAdd.split('?');
  const pathnameWithoutLeadingSlash = pathname.startsWith('/') ? pathname.slice(1) : pathname;
  if (!urlObj.pathname || urlObj.pathname === '/') {
    urlObj.pathname = `/${pathnameWithoutLeadingSlash}`;
  } else {
    const currentPathnameWithoutTrailingSlash = urlObj.pathname.endsWith('/')
      ? urlObj.pathname.slice(0, urlObj.pathname.length - 1)
      : urlObj.pathname;
    urlObj.pathname = `${currentPathnameWithoutTrailingSlash}/${pathnameWithoutLeadingSlash}`;
  }
  if (search) urlObj.search = `${urlObj.search ? `${urlObj.search}&` : ''}${search}`;

  return urlObj.toString();
};
