import { ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import config from 'config';
import getApiBaseUrl from 'utils/getApiBaseUrl';
import { logApiError, logApiInfo } from 'logger';
import {
  GetSlotReservationsResponseData,
  GetSlotReservationsResult,
  SlotReservations,
} from './types';

const slotOrchestrationApiUrl = getApiBaseUrl(config.services.slotOrchestration.path);

class SlotOrchestrationServiceClient extends ServiceClient {
  async getSlotReservations({
    authHeader,
    customerOrderId,
  }: {
    authHeader: string;
    customerOrderId: string;
  }): Promise<GetSlotReservationsResult> {
    const response = await this.httpClient.get<GetSlotReservationsResponseData>(
      `slot-reservations?customerOrderId=${customerOrderId}`,
      {
        headers: {
          Authorization: authHeader,
          breadcrumb: config.applicationName,
        },
      },
    );
    const reservations = response.data;

    if (!response.error) {
      logApiInfo({
        payload: {
          duration: response.duration,
          service: 'slotOrchestration',
          url: slotOrchestrationApiUrl,
          message: `Slot Reservations loaded from server, request took ${response.duration}ms`,
          httpStatus: String(response.status),
          httpStatusCode: response.status,
        },
      });
    } else {
      logApiError({
        payload: {
          duration: response.duration,
          service: 'slotOrchestration',
          url: slotOrchestrationApiUrl,
          message: `Error loading slot reservations: ${response.error.message}`,
          httpStatus: String(response.status),
          httpStatusCode: response.status,
          errorDetails: response.error,
        },
      });
    }

    return {
      reservations: (reservations as SlotReservations) || [],
      error: response.error ? { message: response.error.message } : undefined,
    };
  }
}

const slotOrchestrationServiceClient = new SlotOrchestrationServiceClient(slotOrchestrationApiUrl);

export default slotOrchestrationServiceClient;
