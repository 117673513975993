// TODO - WPIP-67751: Implement flow when a user attempts to checkout
import classnames from 'classnames';
import React from 'react';
import Alert from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Alert';
import Button, { ButtonProps } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Button';
import { Tooltip } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Tooltip';
import Typography from '@johnlewispartnership/wtr-ingredients/dist/foundations/typography';
import { CheckoutConditions } from 'types/Checkout/checkout';
import { useTrolley } from 'contexts/Trolley';
import { urls } from 'constants/urls';
import styles from './GoToCheckout.module.scss';
import MinimumSpendNotMetMessage from './MinimumSpendNotMet/MinimumSpendNotMetMessage';
// import { useAnalytics } from '@johnlewispartnership/wtr-website-analytics';
// import { UPDATE_INSTANT_CHECKOUT_LOCATION } from 'redux/modules/trolley/actions/types';
// import { goToInternalOrExternalLocation } from 'utils/go-to-internal-or-external-location';

type MessagePlacement = 'inline' | 'bottomPopover' | 'topPopover';
// type GetInstantCheckoutReason = 'not_amending' | 'not_allowed' | 'threshold_exceeded' | 'allowed';

export interface GoToCheckoutProps {
  buttonCheckoutId?: string | undefined;
  fullWidth?: boolean;
  hideWhenDisabled?: boolean;
  messagePlacement?: MessagePlacement;
  theme?: ButtonProps['theme'];
  //   destination: string;
  //   location?: string;
  //   canInstantCheckout: boolean;
  //   confirmInstantCheckout: () => void;
  //   canShowInstantCheckoutModal: boolean;
  //   getInstantCheckoutReason?: GetInstantCheckoutReason;
  useLightTooltip?: boolean;
}
export const GoToCheckout = ({
  buttonCheckoutId = undefined,
  fullWidth = false,
  hideWhenDisabled,
  messagePlacement = 'inline',
  theme = 'finalising',
  //   destination = '', // dependent on current page, conflicts and slot
  //   location, // hard coded
  //   canInstantCheckout = false, // returned from trolley response
  //   confirmInstantCheckout, // some kind of state that keeps track of the users current state through the instant checkout flow
  //   canShowInstantCheckoutModal = false, // hardcoded
  //   getInstantCheckoutReason, // comes from whether instant checkout is allowed and if order is being amended which is always in our scenario
  useLightTooltip = false,
}: GoToCheckoutProps) => {
  const { state: trolleyState } = useTrolley();

  // What happens here when window is not available (currently client side rendered so no issue)
  const isOnTrolleyPage = window.location.pathname === urls.trolleyPage;
  const hasHardConflictsOnTrolleyPage =
    isOnTrolleyPage && trolleyState.trolley?.trolleyTotals?.trolleyItemCounts?.hardConflicts;
  const mimiumSpendThresholdMet = trolleyState?.trolley?.trolleyTotals?.minimumSpendThresholdMet;

  const conditions = [
    ...(hasHardConflictsOnTrolleyPage ? [CheckoutConditions.HAS_HARD_CONFLICTS] : []),
    ...(!mimiumSpendThresholdMet ? [CheckoutConditions.MINIMUM_SPEND_NOT_MET] : []),
  ];

  const disabled = conditions.length > 0;

  if (disabled && hideWhenDisabled) return null;

  const { HAS_HARD_CONFLICTS, MINIMUM_SPEND_NOT_MET } = CheckoutConditions;

  const inline = messagePlacement && messagePlacement.includes('inline');
  const buttonId = buttonCheckoutId ?? 'site-header-checkout';

  const inlineMessage = (
    <div id={`${buttonId}-disabled-msg`} className={styles.inlineMsg}>
      {conditions.find(condition => condition === MINIMUM_SPEND_NOT_MET) && (
        <MinimumSpendNotMetMessage isAlert />
      )}
      {conditions.find(condition => condition === HAS_HARD_CONFLICTS) && (
        <Alert
          type="warning"
          title="Unavailable items"
          message="Please remove all unavailable items from your trolley"
        />
      )}
    </div>
  );

  //   const goToCheckout = () => {
  //     // dispatch({ location, type: UPDATE_INSTANT_CHECKOUT_LOCATION });

  //     if (canShowInstantCheckoutModal) {
  //       // prevent sending the GA event in interstitial pages
  //       trackEvent({
  //         event: 'instant_checkout_trigger',
  //         instant_checkout: {
  //           available: canInstantCheckout,
  //           availability_reason: getInstantCheckoutReason,
  //         },
  //       });
  //     }

  //     if (canInstantCheckout && canShowInstantCheckoutModal) {
  //       confirmInstantCheckout();
  //     } else {
  //       goToInternalOrExternalLocation(destination);
  //     }

  //     // if (destination === urls.interstitials) pushEnterCheckoutEvent(location);
  //   };

  const tooltipMessage = (
    <div id="minimumOrder">
      {conditions.find(condition => condition === MINIMUM_SPEND_NOT_MET) && (
        <MinimumSpendNotMetMessage />
      )}
      {conditions.find(condition => condition === HAS_HARD_CONFLICTS) && (
        <Typography styleAs="paragraphHeading" noMargins>
          You have unavailable items in your trolley, please make the required changes in order to
          checkout
        </Typography>
      )}
    </div>
  );

  const checkoutButton = (
    <div className={styles.buttonOverlayTrigger}>
      <Button
        waiting={trolleyState?.loading}
        aria-describedby={`${buttonId}-disabled-msg`}
        aria-label="Checkout"
        data-testid="go-to-checkout-button"
        disabled={disabled}
        id={buttonId}
        label="Checkout"
        // onClick={goToCheckout}
        theme={theme}
        type="submit"
        width="full"
      />
    </div>
  );

  const checkoutOrTooltip =
    messagePlacement.includes('Popover') && disabled ? (
      <Tooltip
        content={tooltipMessage}
        placement={messagePlacement.includes('top') ? 'top' : 'bottom'}
        className={classnames(styles.disabledTooltip, { [styles.light]: useLightTooltip })}
        disableFocusListener
      >
        {checkoutButton}
      </Tooltip>
    ) : (
      checkoutButton
    );

  return (
    <div
      className={classnames({ [styles.checkout]: !inline, [styles.fullSize]: fullWidth })}
      data-testid="go-to-checkout"
    >
      {checkoutOrTooltip}
      {inline && disabled && inlineMessage}
    </div>
  );
};
