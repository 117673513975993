import { CommonModalProps } from 'components/CommonModal/context';
import { ModalCloseOptions } from './modalCloseOptions';
import { ModalSeverityValues } from './modalSeverityValues';

export const unexpectedErrorModal: CommonModalProps = {
  id: 'unexpectedErrorModal',
  severity: ModalSeverityValues.ERROR,
  buttons: [
    {
      buttonText: 'OK',
      buttonValue: ModalCloseOptions.OK,
      onClick: () => window.location.reload(),
    },
  ],
  messageText: [
    'These problems are normally temporary. Please refresh the page, restart your browser or try again a little later.',
  ],
  responseStatus: 503, // Prevent Google indexing the error
  titleText: "Sorry, we're experiencing an unexpected error.",
  cannotClose: true,
};

export const confirmCancelAmendOrderModal: CommonModalProps = {
  id: 'confirmCancelAmendOrderModal',
  buttons: [
    {
      buttonText: 'Yes, cancel changes',
      buttonValue: ModalCloseOptions.OK,
    },
    {
      buttonText: 'Continue shopping',
      buttonValue: ModalCloseOptions.CANCEL,
    },
  ],
  buttonOrientation: 'vertical',
  messageText: [
    'Are you sure you want to cancel your changes to this order?',
    'Your order will revert back to its previous state.',
  ],
  titleText: 'Cancel changes?',
  severity: ModalSeverityValues.INFO,
};
