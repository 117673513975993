import React from 'react';
import { isSeasonalSlotDate, seasonalCutOffDateTime } from 'utils/checkout/is-seasonal-slot-date';
import { formatAmendCutOffDateTime } from 'utils/format-amend-cutoff-date';
import { SlotReservation } from 'services/slotOrchestration/types';
import { OrderTypes } from 'types/Checkout/order-type';
import styles from './AmendOrderAlert.module.scss';

export const AmendCutOffMessage = ({
  slotDate,
  amendOrderCutOff,
  orderType,
}: {
  slotDate: string;
  amendOrderCutOff: SlotReservation['amendOrderCutoffDateTime'];
  orderType: OrderTypes | null;
}) => {
  const isSeasonalSlot = isSeasonalSlotDate(slotDate);

  if (isSeasonalSlot) {
    return (
      <>
        Amend cut-off for Christmas Entertaining items is{' '}
        <span className={styles.cutOffDateTime}>{seasonalCutOffDateTime}</span>
      </>
    );
  }

  if (orderType === OrderTypes.ENTERTAINING) {
    return <>Please check individual notice times before amending Entertaining orders</>;
  }

  if (orderType === OrderTypes.GROCERIES_ENTERTAINING) {
    return <>Notice times vary for Entertaining items</>;
  }

  return (
    <>
      You can amend your order until{' '}
      <span className={styles.cutOffDateTime}>{formatAmendCutOffDateTime(amendOrderCutOff)}</span>
    </>
  );
};
