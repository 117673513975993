import { ServiceClient } from '@johnlewispartnership/wtr-website-api-client';
import Content from 'types/Content';
import config from 'config';
import getApiBaseUrl from 'utils/getApiBaseUrl';
import { logApiError, logApiInfo } from 'logger';
import { safeUrlConcat } from 'utils/url';
import { GetContentResponseData } from './types/GetContentResponseData';

type GetContentError = {
  message: string;
};

export type GetContentResult = {
  content: Content | null;
  error?: GetContentError;
};

const contentApiUrl = getApiBaseUrl(config.services.content.path);
const queryString = '?clientType=WEB_APP';

class ContentServiceClient extends ServiceClient {
  async getContent(
    experienceFragmentKey: string,
    hasTokenSession: boolean,
  ): Promise<GetContentResult> {
    const fullApiUrl = safeUrlConcat(contentApiUrl, `${experienceFragmentKey}${queryString}`);

    const response = await this.httpClient.put<GetContentResponseData>(
      `${experienceFragmentKey}${queryString}`,
      {
        contentUrl: `/content/experience-fragments/waitrosegroceriescms/${experienceFragmentKey}`,
        customerId: '-1',
        customerSearchRequest: {
          queryParams: {},
        },
      },
      {
        headers: {
          Authorization: `Bearer unauthenticated`,
          breadcrumb: config.applicationName,
          ...(hasTokenSession && { 'x-kept-unauthenticated': 'true' }),
        },
      },
    );
    const content = response.data;

    if (!response.error) {
      logApiInfo({
        payload: {
          duration: response.duration,
          service: 'content',
          url: fullApiUrl,
          message: `Content loaded from server, request took ${response.duration}ms`,
          httpStatus: String(response.status),
          httpStatusCode: response.status,
        },
      });
    } else {
      logApiError({
        payload: {
          duration: response.duration,
          service: 'content',
          url: fullApiUrl,
          message: `Error loading content: ${response.error.message}`,
          httpStatus: String(response.status),
          httpStatusCode: response.status,
        },
      });
    }

    return {
      content: content?.locations ? content : null,
      error: response.error ? { message: response.error.message } : undefined,
    };
  }
}

export default new ContentServiceClient(contentApiUrl);
