import { Loggable, Logger } from '@johnlewispartnership/wtr-website-logger';
import Cookies from 'universal-cookie';
import { logger } from 'logger/instance';
import { isWindowAvailable } from 'utils/browser';
import { LoggerRequestContext } from './types';

const getCookies = () => new Cookies();

const logApi =
  (logFn: Logger['error' | 'fatal' | 'warn' | 'info' | 'debug']) =>
  (
    input: Loggable & { payload: Loggable['payload'] & { service: string; url: string } },
    context?: LoggerRequestContext, // You don't need to pass this for client-side calls
  ) => {
    logFn({
      ...input,
      customerId: context?.customerId || getCookies().get('customerId'),
      clientUserAgent:
        context?.clientUserAgent || (isWindowAvailable ? window.navigator.userAgent : 'unknown'),
      ...(context?.clientIpAddress && { clientIpAddress: context.clientIpAddress }), // clientIp doesn't have to be sent client-side. It's there implicitly
      payload: {
        logType: 'apiRequest',
        ...(context && { pageUrl: context.pageUrl }),
        ...input.payload,
        ...(context?.incomingRequestId && { requestId: context.incomingRequestId }),
      },
    });
  };

export const logApiError = logApi(logger.error);
export const logApiWarning = logApi(logger.warn);
export const logApiInfo = logApi(logger.info);
export const logApiDebug = logApi(logger.debug);
export const logApiFatal = logApi(logger.fatal);
