import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Spinner from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Spinner';
import { ModalCloseEvent } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal/Modal';
import { ButtonProps } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Button';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { useTrolley } from 'contexts/Trolley';
import { useSlotContext } from 'contexts/Slot';
import config from 'config';
import AmendOrderAlert from 'components/AmendOrderAlert';
import graphQLService from 'services/graphql';

import { useCommonModal } from 'components/CommonModal';
import { confirmCancelAmendOrderModal, unexpectedErrorModal } from 'constants/modals/modals';
import { ModalCloseOptions } from 'constants/modals/modalCloseOptions';
import { useShoppingContext } from 'contexts/ShoppingContext';
import styles from './SitePinBar.module.scss';

export const SitePinBar = () => {
  const { openModal } = useCommonModal();
  const { shoppingContext } = useShoppingContext();
  const { state: authState } = useAuthentication();
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  // TODO - WPIP-67751: Implement flow when a user attempts to checkout
  // Seems to be used when certain actions take place i.e. dismissing amends or instant checkout
  // src/redux/modules/amend-order/reducer/index.js
  // src/redux/modules/amend-order/selectors/index.js
  // src/redux/modules/amend-order/actions/cancel-amend-order.js
  const [loading, setLoading] = useState(false);
  const [visible, setIsVisible] = useState(false);

  const { state: trolleyState } = useTrolley();
  const {
    slotContext: { details: slotDetails },
  } = useSlotContext();

  const toggleMobileOpen = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  useEffect(() => {
    if (trolleyState.trolley?.amendingOrder && slotDetails && config.features.sitePinBarEnabled)
      setIsVisible(true);
  }, [slotDetails, trolleyState, trolleyState.trolley?.amendingOrder]);

  const cancelAmendOrder = async () => {
    setLoading(true);

    const cancelAmendOrderResponse = await graphQLService.cancelAmendOrder(
      shoppingContext.customerOrderId,
      authState.token!.authHeader,
    );

    if (cancelAmendOrderResponse.error) {
      openModal(unexpectedErrorModal);
    } else {
      setIsVisible(false);
    }

    setLoading(false);
  };

  const onCancelChanges = () => {
    openModal({
      ...confirmCancelAmendOrderModal,
      handleModalClose: (event: ModalCloseEvent) => {
        const { value: modalCloseOption }: { value?: ButtonProps['value'] } = event;
        if (modalCloseOption === ModalCloseOptions.OK) {
          cancelAmendOrder();
        }
      },
    });
  };

  return (
    <>
      {loading && (
        <div className={styles.loading} data-testid="amend-order-loading">
          <Spinner isActive pageSpinner />
        </div>
      )}
      {visible && (
        <aside
          className={classNames(styles.pinBar, {
            [styles.open]: isMobileOpen,
            [styles.closed]: !isMobileOpen,
          })}
          id="sitePinBar"
          data-testid="site-pin-bar"
        >
          <AmendOrderAlert
            isOpen={isMobileOpen}
            onToggle={toggleMobileOpen}
            onCancelChanges={onCancelChanges}
          />
        </aside>
      )}
    </>
  );
};

SitePinBar.displayName = 'SitePinBar';
