import React, { FC } from 'react';
import { Modal } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal';
import { ModalProps } from '@johnlewispartnership/wtr-ingredients/dist/ingredients/Modal/Modal';
import { useCommonModal } from './context';

export const CommonModal: FC = () => {
  const { state, closeModal } = useCommonModal();

  const onCloseHandler: ModalProps['handleModalClose'] = event => {
    if (state.handleModalClose) state.handleModalClose(event);
    closeModal(event);
  };

  return <Modal {...state} handleModalClose={onCloseHandler} />;
};
