import { useContext } from 'react';
import Cookies from 'universal-cookie';

import contentService from 'services/content';
import {
  MenuExperienceFragmentsContext,
  MenuExperienceFragments,
} from './MenuExperienceFragmentsContext';

const cookies = new Cookies();

const useMenuExperienceFragments = () => {
  const menuExperienceFragmentsContext = useContext(MenuExperienceFragmentsContext);

  if (menuExperienceFragmentsContext === undefined) {
    throw new Error('useExperienceFragments must be used within a MenuExperienceFragmentsProvider');
  }

  const { experienceFragments, setExperienceFragments } = menuExperienceFragmentsContext;

  const fetchAndStoreFragment = async (experienceFragmentKey: string) => {
    if (typeof experienceFragments?.[experienceFragmentKey] === 'undefined') {
      const hasTokenSession = cookies.get('X-Has-Token-Session', { doNotParse: true }) === 'true';
      const experienceFragmentBannerContent = await contentService.getContent(
        experienceFragmentKey,
        hasTokenSession,
      );

      if (!experienceFragmentBannerContent.error) {
        setExperienceFragments((prevExperienceFragments: MenuExperienceFragments | undefined) => ({
          ...prevExperienceFragments,
          [experienceFragmentKey]: experienceFragmentBannerContent.content,
        }));
      }
    }
  };

  return {
    experienceFragments,
    fetchAndStoreFragment,
  };
};

export default useMenuExperienceFragments;
