export enum UOMId {
  C62 = 'C62',
  KGM = 'KGM',
  GRM = 'GRM',
  LBR = 'LBR',
}

export type Price = {
  amount: number;
  currencyCode: string;
};

export type Quantity = {
  amount: number | null;
  uom: UOMId;
};

export type TrolleyItem = {
  trolleyItemId: number | undefined;
  productId: string;
  lineNumber: string;
  canSubstitute: boolean;
  noteToShopper?: string | null;
  personalisedMessage?: string | null;
  isPersonalised?: boolean;
  quantity: Quantity;
  totalPrice?: Price;
  retailPrice?: { price: Price; quantity: Quantity };
};

export type GetTrolleyError = {
  message: string | undefined;
  type?: string;
};

export type GraphQuery = {
  query: string;
  variables: {
    [key: string]: any;
  };
};

export type Failure = {
  message: string | undefined;
  type: string | undefined;
};

export type TrolleyTotals = {
  minimumSpendThresholdMet: boolean;
  totalEstimatedCost: {
    amount: number;
    currencyCode: string;
  };
  trolleyItemCounts: {
    hardConflicts: number;
    noConflicts: number;
    softConflicts: number;
  };
  collectionMinimumOrderValue: {
    amount: number;
    currencyCode: string;
  };
  deliveryMinimumOrderValue: {
    amount: number;
    currencyCode: string;
  };
};

export type TrolleyState = {
  slotChangeable: boolean;
  failures: Failure[] | null;
  trolley: {
    amendingOrder: boolean;
    trolleyTotals: TrolleyTotals;
    trolleyItems?: TrolleyItem[];
  } | null;
};

export type Order = {
  containsEntertainingLines: boolean;
};

export type GetTrolleyResponseData = {
  data: {
    getTrolley: TrolleyState;
    getOrderWithRecommendations: {
      order: Order;
    };
  };
};

export type GetTrolleyResult = {
  trolley?: TrolleyState['trolley'];
  order?: Order;
  error?: GetTrolleyError;
  slotChangeable: boolean;
  totalEstimatedCost: number;
  totalNumberOfItems: number;
};

export type GetCancelAmendOrderError = {
  message: string | undefined;
  type?: string;
};

export type GetCancelAmendOrderResult = {
  error?: GetCancelAmendOrderError;
};

export type GetCancelAmendOrderResponseData = {
  data: {
    error?: GetCancelAmendOrderError;
  };
};
