import React, { useCallback, useEffect, useRef, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Close } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { useClientOnlyLayoutEffect } from 'hooks/use-client-only-layout-effect';
import usePrevious from 'hooks/use-previous';
import { setBodyNoScroll } from 'utils/scroll';
import { singleResizeWatcher } from 'utils/single-event-watcher';
import { refType } from 'constants/types/ref';
import CMSMenuPanel from 'components/MegaMenu/CMSMenuPanel';
import MenuLinksList from 'components/MegaMenu/MenuLinksList';
import MenuBannerExperienceFragment from 'cms-components/ExperienceFragments/MenuBannerExperienceFragment';
import styles from './Menus.module.scss';

const MegaMenu = ({
  activeLevel,
  handleClickToClose,
  isOpen,
  loading,
  maxMenus,
  menus,
  navigationRef,
  onToggle,
  scrolled,
  setMegaMenu,
  experienceFragmentKeyForActiveMenuItem,
}) => {
  const previousLevel = usePrevious(activeLevel);
  const previousOpen = usePrevious(isOpen);
  const scrollContainer = useRef();
  const [keyboardLevel, setKeyboardLevel] = useState(null);
  const [hideSubMenu, setHideSubMenu] = useState(false);
  const hasBannerForActiveMenuItem = !!experienceFragmentKeyForActiveMenuItem;

  useEffect(() => {
    if (!previousOpen && isOpen) {
      singleResizeWatcher(() => {
        onToggle(false);
        setBodyNoScroll(false);
      });
    }

    if (previousOpen && !isOpen) {
      setKeyboardLevel(null);
    }
  }, [isOpen, previousOpen, onToggle]);

  useClientOnlyLayoutEffect(() => {
    const { current } = scrollContainer;

    if (current && previousLevel !== activeLevel && isOpen) {
      current.scrollTop = 0;
    }
  });

  const resetMenu = useCallback(
    event => {
      if (activeLevel === 0) {
        handleClickToClose(event);
      } else {
        setHideSubMenu(true);
      }
      setTimeout(() => {
        setMegaMenu(0, '10051');
        setHideSubMenu(false);
      }, 200);
    },
    [activeLevel, handleClickToClose, setMegaMenu],
  );

  return (
    <div
      aria-label="Explore Waitrose"
      className={classNames(styles.nav, {
        [styles.open]: isOpen,
        [styles.closed]: !isOpen,
        [styles.rootActive]: !activeLevel,
        [styles.scrolled]: scrolled,
      })}
      data-testid="megamenu-open"
      id="megamenu"
      role="region"
    >
      {(!loading && activeLevel !== -1 && (
        <nav
          className={classNames(styles.menus, {
            [styles.hideSubMenu]: hideSubMenu,
            [styles.activeLevel]: activeLevel,
          })}
          role="navigation"
          ref={scrollContainer}
        >
          {menus.map((menu, currentLevel) =>
            currentLevel < maxMenus ? (
              <MenuLinksList
                key={menu.id}
                keyboardLevel={keyboardLevel}
                level={currentLevel}
                navigationRef={navigationRef}
                parentId={menus[currentLevel - 1]?.id}
                setKeyboardLevel={setKeyboardLevel}
              />
            ) : null,
          )}
          {activeLevel === 2 && !!hasBannerForActiveMenuItem && (
            <div className={styles.banner}>
              <MenuBannerExperienceFragment
                experienceFragmentKey={experienceFragmentKeyForActiveMenuItem}
                type="desktop"
                onClose={handleClickToClose}
              />
            </div>
          )}
        </nav>
      )) || <p className={styles.loading}>Loading...</p>}
      {!loading && activeLevel === 0 && <CMSMenuPanel onClose={handleClickToClose} />}
      <button
        className={styles.close}
        data-testid="menu-close"
        id="menu-close"
        onClick={event => {
          resetMenu(event);
        }}
        type="button"
      >
        <span className={styles.srOnly}>Close</span>
        <Close className={styles.icon} size="small" />
      </button>
    </div>
  );
};

MegaMenu.displayName = 'MegaMenu';

MegaMenu.propTypes = {
  activeLevel: number,
  handleClickToClose: func.isRequired,
  isOpen: bool,
  loading: bool,
  maxMenus: number,
  menus: arrayOf(
    shape({
      id: string,
    }),
  ),
  navigationRef: refType.isRequired,
  onToggle: func.isRequired,
  setMegaMenu: func,
  scrolled: bool,
  experienceFragmentKeyForActiveMenuItem: string,
};

MegaMenu.defaultProps = {
  activeLevel: 1,
  isOpen: false,
  loading: false,
  maxMenus: 0,
  menus: [],
  setMegaMenu: () => {},
  scrolled: false,
  experienceFragmentKeyForActiveMenuItem: undefined,
};

export default MegaMenu;
