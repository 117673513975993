import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useAuthentication } from '@johnlewispartnership/wtr-website-authentication/dist/context';
import { useShoppingContext } from 'contexts/ShoppingContext';
import slotOrchestrationService from 'services/slotOrchestration';
import addressServiceClient, { GetAddressResult } from 'services/address';
import { SlotReservations } from 'services/slotOrchestration/types';
import branchServiceClient, { GetBranchResult } from 'services/branch';
import { deleteCookie, setCookie } from 'utils/cookie';
import { SlotContextState, SlotContext, type SlotContextValue } from './SlotContext';

const SlotProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { state: authState } = useAuthentication();
  const { shoppingContext } = useShoppingContext();

  const shouldRenderLoggedInVersion = !!(
    (authState.hasTokenSession && !authState.initialised) ||
    (authState.initialised && authState.isLoggedIn)
  );
  const [slotState, setSlotState] = useState<SlotContextState>({
    loading: shouldRenderLoggedInVersion,
    booked: false,
    details: null,
    error: false,
    address: null,
  });

  const getCurrentSlot = (slotReservations: SlotReservations) => {
    const filteredSlotReservations = slotReservations.filter(
      slotReservation => slotReservation.status !== 'EXPIRED',
    );

    if (filteredSlotReservations.length === 0) {
      return null;
    }

    if (filteredSlotReservations.length === 1) {
      return filteredSlotReservations[0];
    }

    return filteredSlotReservations.filter(
      slotReservation => slotReservation.status === 'UNCONFIRMED',
    )[0];
  };

  useEffect(() => {
    const doEffect = async () => {
      setSlotState({
        loading: true,
        booked: false,
        details: null,
        error: false,
        address: null,
      });

      const { reservations, error } = await slotOrchestrationService.getSlotReservations({
        customerOrderId: shoppingContext.customerOrderId,
        authHeader: authState.token!.authHeader,
      });

      const currentSlot = getCurrentSlot(reservations);

      const slotAddressResponse =
        !error && currentSlot && currentSlot.addressId
          ? await addressServiceClient.getAddressById({
              authHeader: authState.token!.authHeader,
              addressId: currentSlot.addressId,
            })
          : ({} as GetAddressResult);

      const slotBranchResponse =
        !error && currentSlot && currentSlot.branchId
          ? await branchServiceClient.getBranchById({
              authHeader: authState.token!.authHeader,
              branchId: String(currentSlot.branchId),
            })
          : ({} as GetBranchResult);

      if (!error && !slotAddressResponse.error && !slotBranchResponse.error && currentSlot) {
        const slotBranchAddress: SlotContextState['address'] = slotBranchResponse.branch && {
          name: slotBranchResponse.branch.name,
          line1: slotBranchResponse.branch.contactDetails?.address1,
          postalCode: slotBranchResponse.branch.contactDetails?.postcode,
          town: slotBranchResponse.branch.contactDetails?.city,
        };
        setCookie(
          'branchId',
          currentSlot.branchId ? String(currentSlot.branchId) : shoppingContext.defaultBranchId,
        );
        setSlotState({
          loading: false,
          booked: true,
          details: currentSlot,
          error: false,
          address: slotAddressResponse.address || slotBranchAddress,
        });
      } else {
        deleteCookie('branchId');
        setSlotState({
          loading: false,
          booked: false,
          details: null,
          error: !!error,
          address: null,
        });
      }
    };

    if (shoppingContext.customerOrderId && authState.token) {
      doEffect();
    } else if (authState.initialised && !authState.isLoggedIn) {
      deleteCookie('branchId');
    }
  }, [
    authState.token,
    shoppingContext.customerOrderId,
    setSlotState,
    shoppingContext.defaultBranchId,
    authState.initialised,
    authState.isLoggedIn,
  ]);

  const providerValue: SlotContextValue = useMemo(
    () => ({
      slotContext: slotState,
      setSlotContext: setSlotState,
    }),
    [slotState],
  );

  return <SlotContext.Provider value={providerValue}>{children}</SlotContext.Provider>;
};

export default SlotProvider;
